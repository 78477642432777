<template>
    <!-- 选择用户 -->
    <div class="aops-select-customers">
        <div class="aop-select-customers-title">选择发送邮件用户</div>
        <div class="aop-select-customers-body">
            <customerTable
                :table-loading.sync="tableLoading"
                :customer-data="customerData"
                :add-cus-data="selectCustomers"
                :quantity="quantity"
                :max-quantity="maxQuantity"
                :type="type"
                @onChangeData="changeData"
            />
            <div class="aop-customers-right">
                <div class="aop-customers-title">已选择用户<span class="aop-customers-num">{{ selectCustomers.length }}人</span>
                </div>
                <el-table
                    ref="addTable"
                    header-cell-class-name="table-head aop-table-head"
                    cell-class-name="table-cell"
                    row-class-name="aop-table-row"
                    :data="selectCustomersCurPage"
                    row-key="id"
                    height="515px"
                    style="width: 100%;"
                >
                    <el-table-column
                        v-for="col in selectColumns"
                        :key="col.prop"
                        :prop="col.prop"
                        :label="col.label"
                        :width="col.width"
                        :min-width="col.minWidth"
                        :align="col.align"
                    >
                        <template v-if="col.prop === 'delete'" slot="header">
                            <el-button
                                type="text"
                                class="aop-table-header-oper"
                                @click="handleDel('all')"
                            >全部删除</el-button>
                        </template>
                        <template slot-scope="{ row }">
                            <div v-if="col.prop === 'delete'" @click="handleDel(row)">
                                <i class="iconfont cursor-pointer">&#xe678;</i>
                            </div>
                            <div v-else-if="col.prop === 'username'">
                                {{ row.username }}
                            </div>
                            <div v-else>{{ row[col.prop] }}</div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="selectCustomers.length !== 0"
                    :current-page.sync="pageNumber"
                    :page-sizes="[10, 20, 50]"
                    :page-size.sync="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="selectCustomers.length"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import customerTable from './table.vue';
import { selectColumns } from './data';

export default {
    components: { customerTable },
    props: {
        selectCustomers: {
            type: Array,
            default: () => []
        },
        quantity: {
            type: Number,
            default: 1
        },
        type: {
            type: String,
            default: 'inception' // 激活码
        },
        maxQuantity: {
            type: Number,
            default: 100
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            tableLoading: true, // 左侧用户表格loading，控制是否重新调取接口获取左侧用户列表数据
            customerData: [], // 左侧用户数据集合
            selectColumns, // 已选用户表格的列
            pageNumber: 1,
            pageSize: 10
        };
    },
    computed: {
        /**
         * 当前页下的已选用户数据
         * @returns {Array}
        */
        selectCustomersCurPage() {
            return this.selectCustomers.slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
        }
    },
    methods: {
        /**
         * 点击全部删除，或者删除单个用户
         * @param {string | object} data all:全部添加/单个用户
        */
        handleDel(data) {
            if (data == 'all') {
                // 删除当前页
                const newSelectCustomers = [...this.selectCustomers];
                newSelectCustomers.splice((this.pageNumber - 1) * this.pageSize, this.selectCustomersCurPage.length);
                if (this.selectCustomers.length == 0) {
                    // this.$$warning('暂无可删除的用户，请先筛选添加吧~');
                    return;
                }
                this.$emit('update:selectCustomers', newSelectCustomers);
                this.tableLoading = true;
            } else {
                // 删除单个用户
                const newSelectCustomers = [...this.selectCustomers];
                const index = newSelectCustomers.findIndex((item) => item.id === data.id);
                newSelectCustomers.splice(index, 1);
                this.$emit('update:selectCustomers', newSelectCustomers);
                this.customerData.forEach((element) => {
                    if (element.id == data.id) {
                        element.isAdd = false;
                        this.$set(element, 'isAdd', false);
                    }
                });
            }
        },
        /**
         * 获取用户列表数据，点击全部添加，添加单个用户，触发此方法
         * 更改用户列表数据，已选用户列表数据
         * @param {Object} data {customers, addCustomers}
        */
        changeData({ customers, addCustomers }) {
            if (customers) {
                this.customerData = customers;
            }
            if (addCustomers) {
                this.$emit('update:selectCustomers', addCustomers);
            }
        }
    }
};
</script>
<style lang="less">
@import "./select_customers.less";
</style>
