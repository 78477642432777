export const tableColumns = [
    {
        prop: 'username',
        label: '用户名称',
        minWidth: 220
    },
    {
        prop: 'email',
        label: '用户邮箱',
        minWidth: 220
    },
    { prop: 'add', label: '全部添加', minWidth: 120, align: 'center' }
];
export const selectColumns = [
    {
        prop: 'username',
        label: '用户名称',
        minWidth: 220
    },
    {
        prop: 'email',
        label: '用户邮箱',
        minWidth: 220
    },
    { prop: 'delete', label: '全部删除', minWidth: 100, align: 'center' }
];
