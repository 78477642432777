//  inception 激活码

import axios from "../uitls/require";

export default {
  activationList(data) {
    return axios.post('/admin/activation-code/list', data)
  },
  createActivation(data) {
    return axios.post('/admin/activation-code/create', data)
  },
  activationInfo(id) {
    return axios.get('/admin/activation-code/info?id=' + id)
  },
  updateActivation(data) {
    return axios.post('/admin/activation-code/update',data)
  },
  applicationList(data) {
    return axios.post('/admin/activation-code-application/list', data)
  },
  reviewApplication(data){
    return axios.post('/admin/activation-code-application/review',data)
  },
  sendEmail(data){
    return axios.post('admin/activation-code/send-email',data)
  },
  customerInfo(params={}){
    return axios.get('/admin/activation-code/customer-info', {params})
  },
  customerList(data){
    return axios.post('/admin/activation-code/customer-list', data)
  }
}
