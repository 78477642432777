import axios from '../uitls/require'

export default {
  // 客户信息
  customerInfo(params = {}) {
    return axios.get('/admin/customer', {params})
  },
  // 客户列表
  customerList(params) {
    return axios.post('/admin/customer/list', params)
  },
  // 新增客户
  customerAdd(customerInfo) {
    return axios.post('/admin/customer/create', customerInfo)
  },
  // 更新客户信息
  customerUpdate(customerInfo) {
    return axios.post('/admin/customer/update', customerInfo)
  },
  // 删除客户
  customerDel(customerId) {
    return axios.get('/admin/customer/delete?id=' + customerId)
  },
  // 启用客户
  customerEnable(customerId) {
    return axios.get('/admin/customer/enable?id=' + customerId)
  },
  // 停用客户
  customerDisable(customerId) {
    return axios.get('/admin/customer/disable?id=' + customerId)
  },
  // 渠道列表
  channelList() {
    return axios.get('/channel/list');
  },
  // 添加积分
  updataBalance(params) {
    return axios.post('/admin/customer/balance-change', params)
  },
  // 积分变动列表
  balanceList(data) {
    return axios.post('/admin/customer/balances', data)
  },
  // 下载记录
  downloadList(data) {
    return axios.post('/admin/downloads', data)
  },
  //购买记录
  purchasedList(data) {
    return axios.post('/admin/customer/purchased-assets', data)
  },
  // 交易记录
  orderList(data) {
    return axios.post('/admin/order/list', data)
  },
  // 更改状态
  changeStatus(data) {
    return axios.post('/admin/customer/change-status', data)
  },
  //更改订阅状态
  changeSubscription(data) {
    return axios.post('admin/customer/subscription-change', data);
  },
  // 收藏资产
  favoritesList(data) {
    return axios.post('/admin/favorites', data)
  },
    /**
    *用户订阅历史记录
    * @param {*} data 入参
    * @return {*}
    */
    fetchHistoryList(data) {
        return axios.post('/admin/subscription/record', data);
    },
  //订阅申请
  subscribeList(data) {
    return axios.post(`/admin/order/company-subscribe-list`,data)
  },
  /**
    * @param {*} id 入参
   * @returns {*}
   */
  subscribeDetail(id) {
    return axios.get(`/admin/order/company-subscribe-detail?id=${id}`)

  },
  /**
   * 处理订阅申请
   * @param {*} data id
   * @returns {*}
   */
  updateSubscribeStatus(data) {
    return axios.post('/admin/order/update-company-subscribe-status', data);
  }
}
