<template>
    <el-form
        ref="activationAdd"
        :model="addForm"
        label-position="left"
        :rules="activationRules"
        inline
    >
        <el-form-item label="激活码名称" prop="name">
            <el-input
                v-model="addForm.name"
                placeholder="输入名称"
            />
        </el-form-item>
        <el-form-item label="有效截止日期" prop="expired">
            <el-date-picker
                v-model="addForm.expired"
                type="date"
                :picker-options="pickerOptions"
                value-format="timestamp"
                :clearable="false"
                placeholder="请选择日期"
            />
        </el-form-item>
        <el-form-item label="生成数量" prop="quantity">
            <el-input-number
                v-model="addForm.quantity"
                placeholder="请输入生成数量"
                :min="1"
                :max="100"
                :controls="false"
            />
        </el-form-item>
      </el-form>
</template>

<script>
import { toDate, formatDate } from 'ecoplants-lib';

export default {
    props: {
        userNum: {
            type: Number,
            default: 0
        },
        addForm: {
            type: Object,
            default: () => ({})
        }
    },
    /**
     * data
     * @returns {*}
    */
    data() {
        /**
         * 生成数量校验
         * @param {*} rule 规则
         * @param {*} value 值
         * @param {*} callback 返回
        */
        const quantityValidator = (rule, value, callback) => {
            if (Number(value) < this.userNum) {
                callback('生成数量应不小于已选择用户数');
            } else {
                callback();
            }
        };
        return {
            activationRules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 1, max: 12, message: '最多可输入12个字符', trigger: 'blur' }
                ],
                expired: [{ required: true, message: '请选择截止时间', trigger: 'blur' }],
                quantity: [
                    { required: true, message: '请输入生成数量', trigger: 'blur' },
                    { validator: quantityValidator, trigger: 'blur' }]
            }
        };
    },
    computed: {
        /**
         * 有效截止日期选择器配置
         * @returns {*}
        */
        pickerOptions() {
            const pickerOptions = {
                /**
                 * 禁用日期 选择范围为当前日期后1天-30天
                 * @param {*} time 日期
                 * @returns {Boolean}
                */
                disabledDate(time) {
                    const max = toDate(formatDate(new Date(), 'yyyy-MM-dd')).getTime() + 30 * 24 * 60 * 60 * 1000;
                    return time.getTime() < Date.now() || time.getTime() > max;
                }
            };
            return pickerOptions;
        }
    },
    methods: {}
};
</script>
