<template>
    <div class="aops-add-activationcode">
        <div class="aop-back" @click="handleBack">
            <i class="iconfont aop-back-icon">&#xe647;</i><span>返回</span>
        </div>
        <div class="aop-add-title">新增激活码</div>
        <addForm
            ref="addForm"
            :add-form="addForm"
            :user-num="selectCustomers.length"
        />
        <div class="aop-send-num">
            发送邮件数<span v-dompurify-html="'\u00a0\u00a0\u00a0\u00a0'" />{{selectCustomers.length}}/{{addForm.quantity}}
        </div>
        <selectCustomers
            :select-customers.sync="selectCustomers"
            :quantity="addForm.quantity"
            type="inception"
        />
        <div class="aop-add-footer">
            <el-button type="primary" class="aop-confirm-button" :loading="btnLoading"  @click="handleSubmit">确认</el-button>
            <el-button class="cancel-button" @click="handleBack">取 消</el-button>
        </div>
  </div>
</template>

<script>
import api from '@/api/inception-key';
import addForm from './form/form.vue';
import selectCustomers from '@/pages/_common/select_customers/select_customers.vue';

export default {
    components: { addForm, selectCustomers },
    /**
     * data
     * @returns {*}
    */
    data() {
        return {
            addForm: {
                name: '',
                expired: new Date().getTime() + 3 * 24 * 60 * 60 * 1000, // 默认当前时间 + 3 uTC 时间戳
                quantity: 1
            },
            selectCustomers: [],
            btnLoading: false
        };
    },
    computed: {},
    methods: {
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/inception-key/activation');
        },
        /**
         * 点击确认按钮
        */
        handleSubmit() {
            this.$refs.addForm.$refs.activationAdd.validate((valid) => {
                if (valid) {
                    this.addNewPromo();
                }
            });
        },
        /**
         * 新增激活码
        */
        addNewPromo() {
            const data = {
                ...this.addForm,
                expired: this.addForm.expired / 1000,
                customer_ids: this.selectCustomers.map((item) => item.id)
            };
            this.btnLoading = true;
            api.createActivation(data).then((res) => {
                if (res.data.data === true) {
                    this.$$success('该激活码创建成功');
                    this.handleBack();
                }
                this.btnLoading = false;
            }, () => {
                this.btnLoading = false;
            });
        }
    }
};
</script>
<style lang="less">
@import "./index.less";
</style>
